<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/1054990_rocket_spacecraft_spaceship_icon.png"
          class="h-20 ml-2 inline-block"
        />
        سفارش تبلیغ سراسری گروه ها
      </h2>
      <ul class="list-disc list-inside mt-3">
        <li class="text-red-500">
          ثبت سفارش تبلیغات به معنی قبول شرایط و
          <router-link to="/" class="text-sky-600">قوانین سفارش</router-link>
          می‌باشد
        </li>
        <li>
          برای خواندن شرایت و نحوه تبلیغات
          <router-link to="/" class="text-sky-600">اینجا کلیک کنید</router-link>
        </li>
        <li>بعد از تکمیل سفارش و پرداخت میتوانید بنر و کلید ها را مشخص کنید</li>
        <li>حد اقل سفارش 10 هزار تومان می‌باشد</li>
      </ul>
    </template>
    <template #default>
      <ErrorBox :errors="errors" class="mb-5" />
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
        <MnrSelect
          v-model="adType.value"
          :options="adType.options"
          @update:modelValue="getDays"
          title="موضوع تبلیغات"
          placeholder="یک مورد را انتخاب کنید"
          hint="اکنون از بین تاریخ های زیر انتخاب کنید"
          oText="name"
          oValue="id"
          dText="سایر موارد"
          :dValue="0"
          :search="true"
          :msg="
            adType.value === null ? 'ابتدا موضوع تبلیغات را انتخاب کنید' : null
          "
        />
        <MnrSelect
          v-model="viewType.value"
          :options="viewType.options"
          title="نوع بازدید"
          placeholder="یک مورد را انتخاب کنید"
          hint="اگر بازدید نمی‌خواهید این گزینه اهمیتی ندارد"
          v-show="adType.value !== null"
        />
        <MnrSelect
          v-model="subKey.value"
          :options="subKey.options"
          title="کلید زیر بنر"
          placeholder="یک مورد را انتخاب کنید"
          hint="این کلید ها زیر بنر قرار می‌گیرد و با کلید شیشه‌ای متفاوت است"
          v-show="adType.value !== null"
          :disabled="viewType.value == 'direct'"
        />
      </div>
      <div class="my-5" v-show="adType.value !== null">
        <Button
          class="text-xs inline-flex h-7 bg-blueGray-100 hover:bg-blueGray-300 active:bg-blueGray-400 text-blueGray-700"
          @click="showPro = !showPro"
        >
          نمایش پنل بلند مدت
        </Button>
        <div
          class="bg-blueGray-100 border border-blueGray-200 rounded-md p-4 my-5"
          v-show="showPro"
        >
          <p class="text-sm mb-3">
            به کمک این پنل می‌توانید تبلیغات بلند مدت را سریع و راحت‌تر انتخاب
            کنید.
          </p>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-4 gap-2 w-full"
          >
            <MnrSelect
              class=""
              v-model="proVal.views"
              :options="proOpt.views"
              :dValue="0"
              dText="بدون بازدید"
              placeholder="یک مورد را انتخاب کنید"
              hint="بازدید هر روز"
            />
            <MnrSelect
              class=""
              v-model="proVal.keys"
              :options="proOpt.keys"
              placeholder="یک مورد را انتخاب کنید"
              hint="کلید هر روز"
            />
            <MnrSelect
              class=""
              v-model="proVal.start"
              :options="rawDays"
              placeholder="تاریخ شروع"
              hint="تاریخ شروع تبلیغ"
              oValue="eng"
              oText="fa"
            />
            <div class="grid grid-cols-2 gap-1 w-full">
              <MnrNumber
                v-model="proVal.days"
                :hint="'تعداد روز:' + proVal.days"
              />
              <MnrNumber
                v-model="proVal.gap"
                :hint="'فاصله روزها :' + proVal.gap"
                :min="0"
                :max="3"
              />
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 w-full mt-5">
            <Button
              class="text-sm inline-flex h-10"
              color="cyan"
              @click="setLong"
            >
              اعمال
            </Button>
            <Button
              class="text-sm inline-flex h-10"
              color="red"
              @click="clearLong"
            >
              پاکسازی همه
            </Button>
          </div>
        </div>
      </div>
      <div class="my-5" v-show="adType.value !== null">
        <h2 class="text-md font-bold">از بین گزینه های زیر انتخاب کنید</h2>
        <p>
          می‌توانید در هر روز مقدار بازدید و کلید را به طور دلخواه انتخاب کنید.
          می‌توانید چند روز یا تنها یک روز را انتخاب کنید
        </p>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-4 w-full my-5"
        >
          <div
            class="bg-blueGray-50 border border-blueGray-200 rounded-md p-2"
            v-for="day in days"
            :key="day.eng"
          >
            <div class="text-center text-coolGray-900 mb-2">
              {{ day.fa }}
            </div>
            <MnrSelect
              v-model="daysVal[day.eng].view"
              :options="day.view"
              placeholder="یک مورد را انتخاب کنید"
              class="mb-2"
              :dValue="0"
              dText="بدون بازدید"
              :status="
                day.view.length == 0
                  ? 'error'
                  : daysVal[day.eng].view
                  ? 'accepted'
                  : ''
              "
            />
            <MnrSelect
              v-model="daysVal[day.eng].keys"
              :options="day.keys"
              placeholder="یک مورد را انتخاب کنید"
              :dValue="0"
              dText="بدون کلید"
              :status="
                day.keys.length == 0
                  ? 'error'
                  : daysVal[day.eng].keys
                  ? 'accepted'
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </template>
    <template #bottom v-if="adType.value !== null">
      <div
        class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-1 md:gap-4 w-full"
      >
        <div class="text-center flex justify-center items-center">
          {{ otherVal.views }}
        </div>
        <div class="text-center flex justify-center items-center">
          {{ otherVal.keys }}
        </div>
        <div class="text-center flex justify-center items-center">
          {{ otherVal.subKeys }}
        </div>
        <div class="text-center flex justify-center items-center">
          {{ otherVal.days }}
        </div>
        <div
          class="text-center flex justify-center items-center font-bold text-lg text-green-700"
        >
          {{ otherVal.price || "0 تومان" }}
        </div>
        <div class="text-center flex justify-center items-center">
          <Button
            class="text-sm h-10 lg:h-12"
            color="green"
            :disabled="disabaledForm || !otherVal.ok"
            @click="submit"
          >
            تکمیل سفارش
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
// import MnrPassword from "@/components/mnr/MnrPassword.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    MnrSelect,
    MnrNumber,
    // MnrPassword,
    Button,
    BodySimple,
    ErrorBox,
  },
  data() {
    return {
      adType: {
        value: null,
        options: [],
      },
      viewType: {
        value: "normal",
        options: [
          {
            value: "normal",
            text: "معمولی",
            hint: "هر کا " + this.$number.format(3500),
          },
          {
            value: "direct",
            text: "مستقیم",
            hint: "هر کا " + this.$number.format(4500),
          },
        ],
      },
      subKey: {
        value: 0,
        options: [
          {
            value: 0,
            text: "بدون کلید",
          },
          {
            value: 1,
            text: "1 کلید",
            hint: "+" + this.$number.format(10000),
          },
          {
            value: 2,
            text: "2 کلید",
            hint: "+" + this.$number.format(20000),
          },
          {
            value: 3,
            text: "3 کلید",
            hint: "+" + this.$number.format(30000),
          },
        ],
      },
      showPro: false,
      errors: [],
      disabaledForm: false,
      firstLoading: true,
      rawDays: [],
      daysVal: {},
      proVal: {
        views: 0,
        keys: 0,
        start: null,
        days: 10,
        gap: 0,
      },
    };
  },
  computed: {
    days() {
      let days = [];
      this.rawDays.forEach((element) => {
        let day = {};
        day.eng = element.eng;
        day.fa = element.fa;
        day.view = (() => {
          let opt = [];
          for (let i = 5000; i <= element.view; i += 5000) {
            opt.push({
              value: i,
              text: i / 1000 + " هزار بازدید",
              hint:
                this.viewType.value == "normal"
                  ? this.$number.format((i / 1000) * 5000) + " تومان"
                  : this.$number.format((i / 1000) * 7000) + " تومان",
            });
          }
          return opt;
        })();
        day.keys = (() => {
          let opt = [];
          for (let i = 1; i <= element.keys; i++) {
            opt.push({
              value: i,
              text: i + " کلید",
              hint: this.$number.format(i * 8000) + " تومان",
            });
          }
          return opt;
        })();
        days.push(day);
      });
      return days;
    },
    otherVal() {
      let daysVal = Object.values(this.daysVal);
      let val = {};
      let sumViews = _.sumBy(daysVal, "view");
      let sumKeys = _.sumBy(daysVal, "keys");
      val.views =
        this.$number.format(sumViews) +
        " بازدید " +
        (this.viewType.value == "normal" ? "معمولی" : "مستقیم");
      val.keys = sumKeys + " کلید";
      val.days =
        _.filter(daysVal, function (o) {
          return o.view || o.keys;
        }).length + " روز";
      val.subKeys = this.viewType.value == "normal" ? this.subKey.value : 0;
      val.subKeys = sumViews ? val.subKeys : 0;
      val.subKeys = val.subKeys + " کلید زیر بنر";
      val.price =
        (sumViews / 1000) * (this.viewType.value == "normal" ? 5000 : 7000) +
        sumKeys * 20000;
      val.price += this.subKey.value * 20000;
      if (val.price > 10000) {
        val.ok = true;
      }
      val.price = this.$number.format(val.price) + " تومان";
      return val;
    },
    proOpt() {
      return {
        views: (() => {
          let opt = [];
          for (let i = 5000; i <= 30000; i += 5000) {
            opt.push({
              value: i,
              text: i / 1000 + " هزار بازدید",
              hint:
                this.viewType.value == "normal"
                  ? this.$number.format((i / 1000) * 5000) + " تومان"
                  : this.$number.format((i / 1000) * 7000) + " تومان",
            });
          }
          return opt;
        })(),
        keys: [
          {
            value: 0,
            text: "بدون کلید",
          },
          {
            value: 1,
            text: "1 کلید",
            hint: "+" + this.$number.format(20000),
          },
          {
            value: 2,
            text: "2 کلید",
            hint: "+" + this.$number.format(40000),
          },
          {
            value: 3,
            text: "3 کلید",
            hint: "+" + this.$number.format(60000),
          },
        ],
      };
    },
  },
  mounted() {
    let $this = this;
    $this.errors = {};
    $this.disabaledForm = true;

    $this.$axios
      .get("/api/ad-types")
      .then(function (response) {
        // console.log(response);
        $this.adType.options = response.data.adTypes;
      })
      .catch(function (error) {
        $this.errors = error.data;
      })
      .finally(function () {
        $this.firstLoading = false;
        $this.disabaledForm = false;
      });
  },
  methods: {
    getDays() {
      let $this = this;
      $this.errors = {};
      $this.disabaledForm = true;

      $this.$axios
        .get("/api/ad-group-days", {
          params: { adTypeId: $this.adType.value },
        })
        .then(function (response) {
          // console.log(response);
          $this.rawDays = response.data.days;
          $this.proVal.start = response.data.days[0].eng;

          response.data.days.forEach((element) => {
            $this.daysVal[element.eng] = {
              date: element.eng,
              view: 0,
              keys: 0,
            };
          });
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.disabaledForm = false;
        });
    },
    setLong() {
      let i = -1;
      let j = 0;
      for (let item in this.daysVal) {
        if (item == this.proVal.start && i <= 0) {
          i = 0;
        }
        if (i >= 0) {
          if (i % (this.proVal.gap + 1) == 0) {
            this.daysVal[item].view = this.proVal.views;
            this.daysVal[item].keys = this.proVal.keys;
            j++;
            if (j >= this.proVal.days) {
              break;
            }
          }
          i++;
        }
      }
    },
    clearLong() {
      for (let item in this.daysVal) {
        this.daysVal[item].view = 0;
        this.daysVal[item].keys = 0;
      }
    },
    submit() {
      let $this = this;
      $this.errors = {};

      // $this.disabaledForm = true;

      $this.$axios
        .post("/api/ad-groups", {
          id: $this.$store.state.user.id,
          adTypeId: $this.adType.value,
          viewType: $this.viewType.value,
          subKey: $this.subKey.value,
          daysVal: $this.daysVal,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({
            name: "GroupOrderCheck",
            params: {
              id: $this.$store.state.user.id,
              payId: response.data.pay.id,
            },
          });
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
          $this.disabaledForm = false;
        });
    },
  },
};
</script>
